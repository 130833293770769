import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import PublicLayout from "./layouts/PublicLayout";

function App() {
  return (
    <>
      <PublicLayout />
    </>
  );
}

export default App;